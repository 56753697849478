.session-list-detail .duration {
  max-width: 60px;
}
.session-list-detail .react-select-container input {
  margin: 0px;
}
.session-list-detail .date-detail .duration {
  max-width: 50px;
  display: inline-block;
}
.session-list-detail .clear-input {
  margin: 0;
}
.session-list-detail .align-price {
  margin: 10px 0;
}
.flatpickr-calendar.arrowBottom::after {
  border-top-color: #aa2f3f;
}
.flatpickr-calendar {
  border: 1px solid white;
  border-color: white;
}
.flatpickr-months .flatpickr-month {
  background: #aa2f3f;
}
.flatpickr-weekdays {
  background: #aa2f3f;
}
span.flatpickr-weekday {
  background: #aa2f3f;
  color: white;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #aa2f3f;
  border-color: #aa2f3f;
}
