.filter-bouton {
  font-size: 0.9em;
  background-color: white;
  color: #4c3c3c;
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
}
.filter-bouton-selected {
  background-color: #aa2f3f;
  color: white;
}
