@charset "UTF-8";
@font-face {
  font-family: "ITC Avant Garde Gothic Pro";
  src: url("../assets/fonts/ITCAvantGardeStd-Book.woff2") format("woff2"), url("../assets/fonts/ITCAvantGardeStd-Book.woff") format("woff"), url("../assets/fonts/ITCAvantGardeStd-Book.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "ITC Avant Garde Gothic Pro";
  src: url("../assets/fonts/ITCAvantGardeStd-Medium.woff2") format("woff2"), url("../assets/fonts/ITCAvantGardeStd-Medium.woff") format("woff"), url("../assets/fonts/ITCAvantGardeStd-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "ITC Avant Garde Gothic Pro";
  src: url("../assets/fonts/ITCAvantGardeStd-Bold.woff2") format("woff2"), url("../assets/fonts/ITCAvantGardeStd-Bold.woff") format("woff"), url("../assets/fonts/ITCAvantGardeStd-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
}
html,
body {
  font-family: "ITC Avant Garde Gothic Pro", Helvetica, sans-serif;
  font-size: 0.9em;
  color: #4c3c3c;
  height: 100%;
  background-color: #e3e3e3;
}
a {
  color: #4c3c3c;
}
a:hover {
  color: #0056b3;
}
nav ul {
  padding-left: 0px;
  padding-right: 0px;
  list-style-type: none;
}
nav ul li::first-letter {
  text-transform: uppercase;
}
h1 {
  background-color: #aa2f3f;
  color: white;
  font-size: 1.1em;
  height: 27px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0px;
}
h1::first-letter {
  text-transform: uppercase;
}
h2,
h3 {
  font-size: 0.9em;
  font-weight: 700;
}
h2::first-letter {
  text-transform: uppercase;
}
h2::after {
  content: ' :';
}
h3 {
  padding-top: 3px;
  padding-bottom: 3px;
}
h3::first-letter {
  text-transform: uppercase;
}
input,
select,
textarea {
  margin: 10px 0;
}
input.form-control,
select.form-control,
textarea.form-control {
  font-size: 0.9em;
  padding: 4px 5px;
}
input.form-control:not([size]):not([multiple]),
select.form-control:not([size]):not([multiple]),
textarea.form-control:not([size]):not([multiple]) {
  height: inherit;
}
button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.was-validated .form-control:valid {
  border-color: #e3e3e3;
}
@media screen and (min-width: 768px) {
  dl,
  dt,
  dd {
    display: inline-block;
    margin: 0;
  }
  dd::after {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: 700;
    content: '•';
  }
}
@media screen and (max-width: 768px) {
  dt {
    margin: 0;
    float: left;
  }
  dd {
    display: block;
  }
}
dt {
  font-weight: 700;
}
dt::after {
  padding-left: 3px;
  padding-right: 3px;
  content: ':';
}
dd:last-child:after {
  display: none;
}
dd::first-letter {
  text-transform: uppercase;
}
thead th::first-letter {
  text-transform: uppercase;
}
/* stylelint-disable declaration-no-important  */
.rounded-top,
.modal-content {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
.rounded-bottom,
.modal-content {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
/* stylelint-enable */
@media screen and (min-width: 768px) {
  .sidebar-element {
    width: 320px;
  }
}
@media screen and (min-width: 768px) {
  .content-element {
    width: 640px;
  }
}
.block-element {
  background-color: white;
  border-bottom: 1px solid #e3e3e3;
  padding: 8px;
}
.block-element li::first-letter {
  text-transform: uppercase;
}
.block-element span {
  display: inline-block;
}
.block-element span::first-letter {
  text-transform: uppercase;
}
.toolbar-element {
  background-color: #e3e3e3;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
.toolbar-element.register {
  background-color: #dce5d7;
}
.toolbar-element ul {
  margin-bottom: 0;
}
.toolbar-element ul li::first-letter {
  text-transform: uppercase;
}
.toolbar-element ul span {
  display: inline-block;
}
.toolbar-element ul span::first-letter {
  text-transform: uppercase;
}
/* stylelint-disable declaration-no-important  */
.displayed {
  display: block!important;
}
.hidden {
  display: none!important;
}
/* stylelint-enable */
.preline {
  white-space: pre-line;
}
.nowrap {
  white-space: nowrap;
}
.word-break {
  word-break: break-word;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: #0056b3;
}
.unclickable {
  cursor: default;
}
.modal-content-confirm {
  max-width: 500px;
}
.modal-content-alert {
  max-width: 500px;
}
.modal-closebutton {
  top: 28px;
  right: 26px;
}
.modal-closebutton-minpos {
  top: 10px;
  right: 10px;
}
.modal-closebutton-confirm {
  top: 10px;
  right: 10px;
}
.modal-closebutton-alert {
  top: 10px;
  right: 10px;
}
.status-color {
  font-weight: 700;
}
.status-color-open {
  color: green;
}
.status-color-close {
  color: blue;
}
.status-color-cancel {
  color: #aa2f3f;
}
.status-color-draft {
  color: purple;
}
.status-color-rating {
  color: orange;
}
.status-color-enable {
  color: green;
}
.status-color-disable {
  color: #aa2f3f;
}
.icon-size-small {
  width: 1em;
  height: 1em;
}
.icon-size-medium {
  width: 1.3em;
  height: 1.3em;
}
.icon-size-big {
  width: 1.8em;
  height: 1.8em;
}
.icon-reverse-color {
  color: white;
}
.icon-circle {
  background-color: white;
  border: 2px solid #4c3c3c;
  border-radius: 50%;
  padding-left: 2px;
  padding-right: 2px;
}
.icon-pictogram {
  font-size: 0.9em;
  margin: 0 3px 3px 0;
}
.icon-comment {
  color: #f3bf0a;
}
.administration {
  overflow-y: auto;
}
