.nav-bar {
  height: 60px;
  background-color: #4c3c3c;
  color: white;
}
.nav-bar .nav-link {
  color: white;
}
.nav-bar .nav-link::first-letter {
  text-transform: uppercase;
}
.nav-bar li > a.nav-link {
  margin: 0px;
  padding: 5px;
}
.nav-bar img.ingeniance-logo {
  top: 50%;
  left: 50%;
  width: 45px;
  height: 45px;
  margin-top: 7px;
  margin-left: 7px;
  background-color: white;
}
.nav-bar .absolute-over {
  position: absolute;
  z-index: 100;
}
.nav-bar .extend-line-left::before {
  content: '';
  position: absolute;
  left: 50px;
  top: 31px;
  width: 15px;
  border-top: 1px solid white;
}
.nav-bar .extend-line-right {
  border-bottom: 1px solid white;
}
.nav-bar .extend-line-right > div {
  margin-top: 5px;
  margin-right: 10px;
  text-align: right;
}
.nav-bar .align-menu {
  padding-left: 65px;
  width: 100%;
  margin: 0 auto;
  display: inline-block;
}
.nav-bar .align-menu-top,
.nav-bar .align-menu-bottom {
  padding: 0px 10px;
}
.nav-bar .align-menu-bottom {
  margin-top: 5px;
}
.nav-bar .tab {
  padding-top: 6px;
  padding-bottom: 4px;
  border-bottom: 1px solid white;
  font-size: 1.1em;
  color: white;
  font-weight: 700;
  white-space: nowrap;
}
.nav-bar .tab:hover {
  color: white;
}
.nav-bar .tab-active {
  position: relative;
  border: 1px solid white;
  border-bottom: 0px none #4c3c3c;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 3px;
  padding-bottom: 0px;
  margin: 2px 5px 0 5px;
}
.nav-bar .tab-active::after,
.nav-bar .tab-active::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 5px;
  bottom: -5px;
  border: 1px solid white;
  border-top: 0px;
}
.nav-bar .tab-active::before {
  border-right: 0px;
  right: -10px;
  border-bottom-left-radius: 5px;
}
.nav-bar .tab-active::after {
  border-left: 0px;
  left: -10px;
  border-bottom-right-radius: 5px;
}
.nav-bar .sub-menu {
  font-size: 0.9em;
  color: white;
  font-weight: 500;
}
.nav-bar .sub-menu:hover {
  color: white;
}
.nav-bar .sub-menu-active {
  font-weight: 700;
}
.nav-bar .badge {
  font-size: 0.9em;
  position: relative;
  top: -1px;
}
.nav-bar .badge-danger {
  background-color: #aa2f3f;
  padding-top: 4px;
  padding-bottom: 4px;
}
.nav-bar .badge-danger[href]:hover,
.nav-bar .badge-danger[href]:focus {
  background-color: #aa2f3f;
}
.nav-bar .dropdown .show {
  margin-left: -23px;
  transition: all 0.1s;
}
.nav-bar .dropdown-menu {
  border-top-width: 0px;
  background-color: #4c3c3c;
}
.nav-bar .dropdown-item {
  color: white;
}
.nav-bar .dropdown-item .nav-link {
  font-size: 0.8em;
}
.nav-bar .dropdown-item .tab {
  border-bottom: none;
  padding-top: 0;
  padding-bottom: 0;
}
.nav-bar .dropdown-item:focus,
.nav-bar .dropdown-item:hover {
  text-decoration: none;
  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-color: currentcolor;
  background-color: #aa2f3f;
}
.nav-bar-mobile {
  height: inherit;
  background-color: #4c3c3c;
  color: white;
}
.nav-bar-mobile .active {
  background-color: #aa2f3f;
}
.nav-bar-mobile .nav-item.sub-menu {
  background-color: #e3e3e3;
}
.nav-bar-mobile .nav-item.sub-menu .nav-link {
  color: #4c3c3c;
}
.nav-bar-mobile .nav-item.sub-menu .active {
  color: white;
}
