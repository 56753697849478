.nav-bar {
  height: 60px;
  background-color: #4c3c3c;
  color: white;
}
.nav-bar .nav-link {
  color: white;
}
.nav-bar .nav-link::first-letter {
  text-transform: uppercase;
}
.nav-bar li > a.nav-link {
  margin: 0px;
  padding: 5px;
}
.nav-bar img.ingeniance-logo {
  top: 50%;
  left: 50%;
  width: 45px;
  height: 45px;
  margin-top: 7px;
  margin-left: 7px;
  background-color: white;
}
.nav-bar .absolute-over {
  position: absolute;
  z-index: 100;
}
.nav-bar .extend-line-left::before {
  content: '';
  position: absolute;
  left: 50px;
  top: 31px;
  width: 15px;
  border-top: 1px solid white;
}
.nav-bar .extend-line-right {
  border-bottom: 1px solid white;
}
.nav-bar .extend-line-right > div {
  margin-top: 5px;
  margin-right: 10px;
  text-align: right;
}
.nav-bar .align-menu {
  padding-left: 65px;
  width: 100%;
  margin: 0 auto;
  display: inline-block;
}
.nav-bar .align-menu-top,
.nav-bar .align-menu-bottom {
  padding: 0px 10px;
}
.nav-bar .align-menu-bottom {
  margin-top: 5px;
}
.nav-bar .tab {
  padding-top: 6px;
  padding-bottom: 4px;
  border-bottom: 1px solid white;
  font-size: 1.1em;
  color: white;
  font-weight: 700;
  white-space: nowrap;
}
.nav-bar .tab:hover {
  color: white;
}
.nav-bar .tab-active {
  position: relative;
  border: 1px solid white;
  border-bottom: 0px none #4c3c3c;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 3px;
  padding-bottom: 0px;
  margin: 2px 5px 0 5px;
}
.nav-bar .tab-active::after,
.nav-bar .tab-active::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 5px;
  bottom: -5px;
  border: 1px solid white;
  border-top: 0px;
}
.nav-bar .tab-active::before {
  border-right: 0px;
  right: -10px;
  border-bottom-left-radius: 5px;
}
.nav-bar .tab-active::after {
  border-left: 0px;
  left: -10px;
  border-bottom-right-radius: 5px;
}
.nav-bar .sub-menu {
  font-size: 0.9em;
  color: white;
  font-weight: 500;
}
.nav-bar .sub-menu:hover {
  color: white;
}
.nav-bar .sub-menu-active {
  font-weight: 700;
}
.nav-bar .badge {
  font-size: 0.9em;
  position: relative;
  top: -1px;
}
.nav-bar .badge-danger {
  background-color: #aa2f3f;
  padding-top: 4px;
  padding-bottom: 4px;
}
.nav-bar .badge-danger[href]:hover,
.nav-bar .badge-danger[href]:focus {
  background-color: #aa2f3f;
}
.nav-bar .dropdown .show {
  margin-left: -23px;
  transition: all 0.1s;
}
.nav-bar .dropdown-menu {
  border-top-width: 0px;
  background-color: #4c3c3c;
}
.nav-bar .dropdown-item {
  color: white;
}
.nav-bar .dropdown-item .nav-link {
  font-size: 0.8em;
}
.nav-bar .dropdown-item .tab {
  border-bottom: none;
  padding-top: 0;
  padding-bottom: 0;
}
.nav-bar .dropdown-item:focus,
.nav-bar .dropdown-item:hover {
  text-decoration: none;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  -webkit-text-decoration-color: currentcolor;
          text-decoration-color: currentcolor;
  background-color: #aa2f3f;
}
.nav-bar-mobile {
  height: inherit;
  background-color: #4c3c3c;
  color: white;
}
.nav-bar-mobile .active {
  background-color: #aa2f3f;
}
.nav-bar-mobile .nav-item.sub-menu {
  background-color: #e3e3e3;
}
.nav-bar-mobile .nav-item.sub-menu .nav-link {
  color: #4c3c3c;
}
.nav-bar-mobile .nav-item.sub-menu .active {
  color: white;
}

.filter-bouton {
  font-size: 0.9em;
  background-color: white;
  color: #4c3c3c;
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
}
.filter-bouton-selected {
  background-color: #aa2f3f;
  color: white;
}

.session-list-detail .duration {
  max-width: 60px;
}
.session-list-detail .react-select-container input {
  margin: 0px;
}
.session-list-detail .date-detail .duration {
  max-width: 50px;
  display: inline-block;
}
.session-list-detail .clear-input {
  margin: 0;
}
.session-list-detail .align-price {
  margin: 10px 0;
}
.flatpickr-calendar.arrowBottom::after {
  border-top-color: #aa2f3f;
}
.flatpickr-calendar {
  border: 1px solid white;
  border-color: white;
}
.flatpickr-months .flatpickr-month {
  background: #aa2f3f;
}
.flatpickr-weekdays {
  background: #aa2f3f;
}
span.flatpickr-weekday {
  background: #aa2f3f;
  color: white;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #aa2f3f;
  border-color: #aa2f3f;
}

.session-detail {
  background-color: white;
}
.session-detail-table thead > tr > th {
  border-top: 1px none #fff;
}
.session-detail-table svg {
  margin-right: 5px;
}
.session-detail-table input,
.session-detail-table select,
.session-detail-table textarea {
  margin: 0px;
}
.session-detail-subscribe ul {
  margin: 0;
  padding-left: 3px;
  padding-right: 3px;
}
.session-detail-subscribe li {
  display: inline-block;
  padding-left: 3px;
  padding-right: 3px;
}
.session-detail-subscribe li span::first-letter {
  text-transform: uppercase;
}

.contactSubs #selectRoles input {
  margin: 0px;
}
.contactSubs .wrapper {
  height: 400px;
  min-width: 100%;
}
.contactSubs .editor {
  overflow-y: auto;
  height: 310px;
}
.contactSubs .toolbar {
  height: 70px;
  border: none;
}
.contactSubs .style-object {
  height: 27px;
}

.training-detail {
  background-color: white;
}
.training-detail select,
.training-detail input,
.training-detail textarea {
  margin-top: 0px;
  margin-bottom: 0px;
}
.training-detail dd > select,
.training-detail dd > input {
  display: inline-block;
}
.training-detail .form-control {
  width: inherit;
}
.training-detail-label {
  font-size: 1.1em;
  font-weight: 700;
  width: 70%;
}
.training-detail-label span {
  margin-right: 5px;
}
.training-detail-label span::after {
  content: ':';
}
.training-detail-label span::first-letter {
  text-transform: uppercase;
}
.training-detail-provider::first-letter {
  text-transform: uppercase;
}
.training-detail-provider > span,
.training-detail .editable > span,
.training-detail .editable > input {
  display: inline-flex;
  align-items: center;
}
.training-detail .duration {
  max-width: 60px;
}
.training-detail .plan {
  min-height: 150px;
}
.training-detail .categories {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .training-detail dt,
  .training-detail dd {
    display: flex;
    width: 40%;
  }
}
.training-detail .initial {
  width: initial;
}

.training-card {
  background-color: white;
}
.training-card-missing p {
  padding: 10px 10%;
}
.training-card-label {
  font-size: 1.1em;
  font-weight: 700;
}
.training-card-detail {
  border-top: 1px solid #e3e3e3;
}
@media screen and (max-width: 768px) {
  .training-card dl {
    margin-top: 10px;
  }
  .training-card dt,
  .training-card dd {
    float: left;
  }
  .training-card dt {
    clear: both;
  }
}

.loading {
  background-color: white;
}
.loading-label {
  font-size: 1.1em;
  font-weight: 700;
}
.loading-detail {
  border-top: 1px solid #e3e3e3;
}
.loading [role='progressbar'] {
  position: relative;
  padding: 0;
  width: 100px;
  height: 25px;
  list-style: none;
}
.loading li {
  display: block;
  position: absolute;
  right: 0;
  width: 3px;
  height: 25px;
  border-radius: 1px;
  /* Rotate from the middle of the bottom */
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  background-color: #4c3c3c;
  -webkit-animation: bar linear 2.8s infinite;
          animation: bar linear 2.8s infinite;
  /* Tell the browser which properties will change in the animation */
  will-change: transform;
  /* Set default states for browsers that don't support animations */
}
.loading li:nth-child(1) {
  -webkit-transform: translateX(0) rotate(0deg);
          transform: translateX(0) rotate(0deg);
  opacity: 0.2;
}
.loading li:nth-child(2) {
  -webkit-transform: translateX(-15px) rotate(0deg);
          transform: translateX(-15px) rotate(0deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.loading li:nth-child(3) {
  -webkit-transform: translateX(-30px) rotate(0deg);
          transform: translateX(-30px) rotate(0deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.loading li:nth-child(4) {
  -webkit-transform: translateX(-45px) rotate(10deg);
          transform: translateX(-45px) rotate(10deg);
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}
.loading li:nth-child(5) {
  -webkit-transform: translateX(-60px) rotate(40deg);
          transform: translateX(-60px) rotate(40deg);
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
}
.loading li:nth-child(6) {
  -webkit-transform: translateX(-75px) rotate(62deg);
          transform: translateX(-75px) rotate(62deg);
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
}
.loading li:nth-child(7) {
  -webkit-transform: translateX(-90px) rotate(72deg);
          transform: translateX(-90px) rotate(72deg);
  -webkit-animation-delay: -2.4s;
          animation-delay: -2.4s;
}
@-webkit-keyframes bar {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 0;
  }
  14.28% {
    -webkit-transform: translateX(-15px) rotate(0deg);
            transform: translateX(-15px) rotate(0deg);
    opacity: 1;
  }
  28.56% {
    -webkit-transform: translateX(-30px) rotate(0deg);
            transform: translateX(-30px) rotate(0deg);
    opacity: 1;
  }
  37.12% {
    -webkit-transform: translateX(-39px) rotate(0deg);
            transform: translateX(-39px) rotate(0deg);
    opacity: 1;
  }
  42.84% {
    -webkit-transform: translateX(-45px) rotate(10deg);
            transform: translateX(-45px) rotate(10deg);
    opacity: 1;
  }
  57.12% {
    -webkit-transform: translateX(-60px) rotate(40deg);
            transform: translateX(-60px) rotate(40deg);
    opacity: 1;
  }
  71.4% {
    -webkit-transform: translateX(-75px) rotate(62deg);
            transform: translateX(-75px) rotate(62deg);
    opacity: 1;
  }
  85.68% {
    -webkit-transform: translateX(-90px) rotate(72deg);
            transform: translateX(-90px) rotate(72deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-105px) rotate(74deg);
            transform: translateX(-105px) rotate(74deg);
    opacity: 0;
  }
}
@keyframes bar {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 0;
  }
  14.28% {
    -webkit-transform: translateX(-15px) rotate(0deg);
            transform: translateX(-15px) rotate(0deg);
    opacity: 1;
  }
  28.56% {
    -webkit-transform: translateX(-30px) rotate(0deg);
            transform: translateX(-30px) rotate(0deg);
    opacity: 1;
  }
  37.12% {
    -webkit-transform: translateX(-39px) rotate(0deg);
            transform: translateX(-39px) rotate(0deg);
    opacity: 1;
  }
  42.84% {
    -webkit-transform: translateX(-45px) rotate(10deg);
            transform: translateX(-45px) rotate(10deg);
    opacity: 1;
  }
  57.12% {
    -webkit-transform: translateX(-60px) rotate(40deg);
            transform: translateX(-60px) rotate(40deg);
    opacity: 1;
  }
  71.4% {
    -webkit-transform: translateX(-75px) rotate(62deg);
            transform: translateX(-75px) rotate(62deg);
    opacity: 1;
  }
  85.68% {
    -webkit-transform: translateX(-90px) rotate(72deg);
            transform: translateX(-90px) rotate(72deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-105px) rotate(74deg);
            transform: translateX(-105px) rotate(74deg);
    opacity: 0;
  }
}

.session-card {
  background-color: white;
}
.session-card-missing p {
  padding: 10px 10%;
}
.session-card.register {
  background-color: #c3d2be;
}
.session-card-label {
  font-size: 1.1em;
  font-weight: 700;
}
.session-card ul {
  padding-left: 0px;
  padding-right: 0px;
  list-style-type: none;
}
.session-card .wrap-word {
  word-wrap: break-word;
}
.star-container {
  top: -2px;
}

@charset "UTF-8";
@font-face {
  font-family: "ITC Avant Garde Gothic Pro";
  src: url(/static/media/ITCAvantGardeStd-Book.db1f0383.woff2) format("woff2"), url(/static/media/ITCAvantGardeStd-Book.3772cda4.woff) format("woff"), url(/static/media/ITCAvantGardeStd-Book.d7ed911a.otf) format("opentype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "ITC Avant Garde Gothic Pro";
  src: url(/static/media/ITCAvantGardeStd-Medium.9d90a477.woff2) format("woff2"), url(/static/media/ITCAvantGardeStd-Medium.ab2eff69.woff) format("woff"), url(/static/media/ITCAvantGardeStd-Medium.8c3c83c1.otf) format("opentype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "ITC Avant Garde Gothic Pro";
  src: url(/static/media/ITCAvantGardeStd-Bold.5ea0e947.woff2) format("woff2"), url(/static/media/ITCAvantGardeStd-Bold.49f8e88d.woff) format("woff"), url(/static/media/ITCAvantGardeStd-Bold.e72722fb.otf) format("opentype");
  font-style: normal;
  font-weight: 700;
}
html,
body {
  font-family: "ITC Avant Garde Gothic Pro", Helvetica, sans-serif;
  font-size: 0.9em;
  color: #4c3c3c;
  height: 100%;
  background-color: #e3e3e3;
}
a {
  color: #4c3c3c;
}
a:hover {
  color: #0056b3;
}
nav ul {
  padding-left: 0px;
  padding-right: 0px;
  list-style-type: none;
}
nav ul li::first-letter {
  text-transform: uppercase;
}
h1 {
  background-color: #aa2f3f;
  color: white;
  font-size: 1.1em;
  height: 27px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0px;
}
h1::first-letter {
  text-transform: uppercase;
}
h2,
h3 {
  font-size: 0.9em;
  font-weight: 700;
}
h2::first-letter {
  text-transform: uppercase;
}
h2::after {
  content: ' :';
}
h3 {
  padding-top: 3px;
  padding-bottom: 3px;
}
h3::first-letter {
  text-transform: uppercase;
}
input,
select,
textarea {
  margin: 10px 0;
}
input.form-control,
select.form-control,
textarea.form-control {
  font-size: 0.9em;
  padding: 4px 5px;
}
input.form-control:not([size]):not([multiple]),
select.form-control:not([size]):not([multiple]),
textarea.form-control:not([size]):not([multiple]) {
  height: inherit;
}
button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.was-validated .form-control:valid {
  border-color: #e3e3e3;
}
@media screen and (min-width: 768px) {
  dl,
  dt,
  dd {
    display: inline-block;
    margin: 0;
  }
  dd::after {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: 700;
    content: '\2022';
  }
}
@media screen and (max-width: 768px) {
  dt {
    margin: 0;
    float: left;
  }
  dd {
    display: block;
  }
}
dt {
  font-weight: 700;
}
dt::after {
  padding-left: 3px;
  padding-right: 3px;
  content: ':';
}
dd:last-child:after {
  display: none;
}
dd::first-letter {
  text-transform: uppercase;
}
thead th::first-letter {
  text-transform: uppercase;
}
/* stylelint-disable declaration-no-important  */
.rounded-top,
.modal-content {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
.rounded-bottom,
.modal-content {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
/* stylelint-enable */
@media screen and (min-width: 768px) {
  .sidebar-element {
    width: 320px;
  }
}
@media screen and (min-width: 768px) {
  .content-element {
    width: 640px;
  }
}
.block-element {
  background-color: white;
  border-bottom: 1px solid #e3e3e3;
  padding: 8px;
}
.block-element li::first-letter {
  text-transform: uppercase;
}
.block-element span {
  display: inline-block;
}
.block-element span::first-letter {
  text-transform: uppercase;
}
.toolbar-element {
  background-color: #e3e3e3;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
.toolbar-element.register {
  background-color: #dce5d7;
}
.toolbar-element ul {
  margin-bottom: 0;
}
.toolbar-element ul li::first-letter {
  text-transform: uppercase;
}
.toolbar-element ul span {
  display: inline-block;
}
.toolbar-element ul span::first-letter {
  text-transform: uppercase;
}
/* stylelint-disable declaration-no-important  */
.displayed {
  display: block!important;
}
.hidden {
  display: none!important;
}
/* stylelint-enable */
.preline {
  white-space: pre-line;
}
.nowrap {
  white-space: nowrap;
}
.word-break {
  word-break: break-word;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: #0056b3;
}
.unclickable {
  cursor: default;
}
.modal-content-confirm {
  max-width: 500px;
}
.modal-content-alert {
  max-width: 500px;
}
.modal-closebutton {
  top: 28px;
  right: 26px;
}
.modal-closebutton-minpos {
  top: 10px;
  right: 10px;
}
.modal-closebutton-confirm {
  top: 10px;
  right: 10px;
}
.modal-closebutton-alert {
  top: 10px;
  right: 10px;
}
.status-color {
  font-weight: 700;
}
.status-color-open {
  color: green;
}
.status-color-close {
  color: blue;
}
.status-color-cancel {
  color: #aa2f3f;
}
.status-color-draft {
  color: purple;
}
.status-color-rating {
  color: orange;
}
.status-color-enable {
  color: green;
}
.status-color-disable {
  color: #aa2f3f;
}
.icon-size-small {
  width: 1em;
  height: 1em;
}
.icon-size-medium {
  width: 1.3em;
  height: 1.3em;
}
.icon-size-big {
  width: 1.8em;
  height: 1.8em;
}
.icon-reverse-color {
  color: white;
}
.icon-circle {
  background-color: white;
  border: 2px solid #4c3c3c;
  border-radius: 50%;
  padding-left: 2px;
  padding-right: 2px;
}
.icon-pictogram {
  font-size: 0.9em;
  margin: 0 3px 3px 0;
}
.icon-comment {
  color: #f3bf0a;
}
.administration {
  overflow-y: auto;
}

.error-handler h2 {
  font-size: 1.1em;
}

