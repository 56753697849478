.training-detail {
  background-color: white;
}
.training-detail select,
.training-detail input,
.training-detail textarea {
  margin-top: 0px;
  margin-bottom: 0px;
}
.training-detail dd > select,
.training-detail dd > input {
  display: inline-block;
}
.training-detail .form-control {
  width: inherit;
}
.training-detail-label {
  font-size: 1.1em;
  font-weight: 700;
  width: 70%;
}
.training-detail-label span {
  margin-right: 5px;
}
.training-detail-label span::after {
  content: ':';
}
.training-detail-label span::first-letter {
  text-transform: uppercase;
}
.training-detail-provider::first-letter {
  text-transform: uppercase;
}
.training-detail-provider > span,
.training-detail .editable > span,
.training-detail .editable > input {
  display: inline-flex;
  align-items: center;
}
.training-detail .duration {
  max-width: 60px;
}
.training-detail .plan {
  min-height: 150px;
}
.training-detail .categories {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .training-detail dt,
  .training-detail dd {
    display: flex;
    width: 40%;
  }
}
.training-detail .initial {
  width: initial;
}
