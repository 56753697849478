.session-card {
  background-color: white;
}
.session-card-missing p {
  padding: 10px 10%;
}
.session-card.register {
  background-color: #c3d2be;
}
.session-card-label {
  font-size: 1.1em;
  font-weight: 700;
}
.session-card ul {
  padding-left: 0px;
  padding-right: 0px;
  list-style-type: none;
}
.session-card .wrap-word {
  word-wrap: break-word;
}
.star-container {
  top: -2px;
}
