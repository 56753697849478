.training-card {
  background-color: white;
}
.training-card-missing p {
  padding: 10px 10%;
}
.training-card-label {
  font-size: 1.1em;
  font-weight: 700;
}
.training-card-detail {
  border-top: 1px solid #e3e3e3;
}
@media screen and (max-width: 768px) {
  .training-card dl {
    margin-top: 10px;
  }
  .training-card dt,
  .training-card dd {
    float: left;
  }
  .training-card dt {
    clear: both;
  }
}
