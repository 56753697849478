.contactSubs #selectRoles input {
  margin: 0px;
}
.contactSubs .wrapper {
  height: 400px;
  min-width: 100%;
}
.contactSubs .editor {
  overflow-y: auto;
  height: 310px;
}
.contactSubs .toolbar {
  height: 70px;
  border: none;
}
.contactSubs .style-object {
  height: 27px;
}
