.loading {
  background-color: white;
}
.loading-label {
  font-size: 1.1em;
  font-weight: 700;
}
.loading-detail {
  border-top: 1px solid #e3e3e3;
}
.loading [role='progressbar'] {
  position: relative;
  padding: 0;
  width: 100px;
  height: 25px;
  list-style: none;
}
.loading li {
  display: block;
  position: absolute;
  right: 0;
  width: 3px;
  height: 25px;
  border-radius: 1px;
  /* Rotate from the middle of the bottom */
  transform-origin: 50% 100%;
  background-color: #4c3c3c;
  animation: bar linear 2.8s infinite;
  /* Tell the browser which properties will change in the animation */
  will-change: transform;
  /* Set default states for browsers that don't support animations */
}
.loading li:nth-child(1) {
  transform: translateX(0) rotate(0deg);
  opacity: 0.2;
}
.loading li:nth-child(2) {
  transform: translateX(-15px) rotate(0deg);
  animation-delay: -0.4s;
}
.loading li:nth-child(3) {
  transform: translateX(-30px) rotate(0deg);
  animation-delay: -0.8s;
}
.loading li:nth-child(4) {
  transform: translateX(-45px) rotate(10deg);
  animation-delay: -1.2s;
}
.loading li:nth-child(5) {
  transform: translateX(-60px) rotate(40deg);
  animation-delay: -1.6s;
}
.loading li:nth-child(6) {
  transform: translateX(-75px) rotate(62deg);
  animation-delay: -2s;
}
.loading li:nth-child(7) {
  transform: translateX(-90px) rotate(72deg);
  animation-delay: -2.4s;
}
@keyframes bar {
  0% {
    transform: translateX(0) rotate(0deg);
    opacity: 0;
  }
  14.28% {
    transform: translateX(-15px) rotate(0deg);
    opacity: 1;
  }
  28.56% {
    transform: translateX(-30px) rotate(0deg);
    opacity: 1;
  }
  37.12% {
    transform: translateX(-39px) rotate(0deg);
    opacity: 1;
  }
  42.84% {
    transform: translateX(-45px) rotate(10deg);
    opacity: 1;
  }
  57.12% {
    transform: translateX(-60px) rotate(40deg);
    opacity: 1;
  }
  71.4% {
    transform: translateX(-75px) rotate(62deg);
    opacity: 1;
  }
  85.68% {
    transform: translateX(-90px) rotate(72deg);
    opacity: 1;
  }
  100% {
    transform: translateX(-105px) rotate(74deg);
    opacity: 0;
  }
}
