.session-detail {
  background-color: white;
}
.session-detail-table thead > tr > th {
  border-top: 1px none #fff;
}
.session-detail-table svg {
  margin-right: 5px;
}
.session-detail-table input,
.session-detail-table select,
.session-detail-table textarea {
  margin: 0px;
}
.session-detail-subscribe ul {
  margin: 0;
  padding-left: 3px;
  padding-right: 3px;
}
.session-detail-subscribe li {
  display: inline-block;
  padding-left: 3px;
  padding-right: 3px;
}
.session-detail-subscribe li span::first-letter {
  text-transform: uppercase;
}
